import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@enviroments/environment";
import { GenetareAccessTokenResponseDto } from "./dto/generate-access-token.response.dto";
import { GenerarteSessionTokenRequestDto } from "./dto/generate-session-token-request.dto";
import { GenerarteSessionTokenResponseDto } from "./dto/generate-session-token.response.dto";
import { TransactionAuthorizationRequestDto } from "./dto/transaction-authorization-request.dto";
import { TransactionAuthorizationResponseDto } from "./dto/transaction-authorization-response.dto";

@Injectable({
  providedIn: 'root'
})
export class NiubizService {

  constructor(
    private http: HttpClient
  ){}

  public getGenerateAccessToken(){
    return this.http
    .get<GenetareAccessTokenResponseDto>(`${environment.baseApi}/niubiz/accessToken`)
    .toPromise();
  }


  public generateSessionToken(request: GenerarteSessionTokenRequestDto){
    return this.http
    .post<GenerarteSessionTokenResponseDto>(`${environment.baseApi}/niubiz/sessionToken`, request)
    .toPromise();
  }

  public transactionAuthorization(transactionRequest: TransactionAuthorizationRequestDto){
    return this.http
    .post<TransactionAuthorizationResponseDto>(`${environment.baseApi}/niubiz/transactionAuthorization`, transactionRequest)
    .toPromise();
  }
}
